import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import ReCaptcha from "react-google-recaptcha"
import axios from "axios"
import Img from "gatsby-image"
import styled from "@emotion/styled"

import { device, visuallyHidden } from "../utils/mixins"
import dsm from "../utils/dsm"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"

import {
  PageSectionTitle,
  I2BronzeText,
  BodyText,
  BodyBold,
  BodyLink,
} from "../components/textComponents"
import { PageImageButton } from "../components/interactiveComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

var SignUpTitleCont = styled.div`
  display: flex;
  margin-top: 1.25rem;
`

var SignUpTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 750;
  ${dsm.fonts.primary}

  ${device.mobileM} {
    font-size: 1.4rem;
  }
`

var I2IconCont = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 0.5rem;
`

var SignUpButton = styled.button`
  background: ${dsm.color.brand.copper.base};
  border: 0;
  color: black;
  text-decoration: none;
  border-radius: 1.25rem;
  font-size: 1.25rem;
  padding: 0.5rem;
  ${dsm.fonts.primary};
  display: block;
  font-weight: 750;
  z-index: 1;
  box-shadow: 0.1rem 0.25rem 0.75rem rgba(0, 0, 0, 0.75);
  transition: background 0.35s ease-out;
  width: 100%;
  text-align: left;
  padding-left: 1.25rem;
  margin-top: 1.25rem;

  :hover {
    background: #debc99;
  }

  ${device.mobileM} {
    padding: 0.65rem;
    padding-left: 1.25rem;
    font-size: 1.4rem;
  }

  ${device.tablet} {
    width: 100%;
  }
`

var FormContainer = styled(Form)`
  ${device.tablet} {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  label {
    ${visuallyHidden}
  }

  input {
    width: 100%;
    height: 2.75rem;
    font-size: 1.25rem;
    margin-bottom: 0.15rem;
    border-radius: 0.25rem;
    border: 0.05rem solid #979797;
    background: #eae7e7;
    padding-left: 0.75rem;
  }
`

var EmailInput = styled(Field)`
  border-color: ${props => (props.error ? "#de2424" : "#979797")} !important;
`

var CompanyInput = styled(Field)`
  border-color: ${props => (props.error ? "#de2424" : "#979797")} !important;
`

var ErrorsContainer = styled.div`
  height: 1.5rem;

  span {
    font-size: 1rem;
    color: #de2424;
    font-weight: 600;
    margin-left: 0.25rem;

    ${device.mobileM} {
      font-size: 1.1rem;
    }
  }
`

var FormSectionCont = styled.div`
  ${device.tablet} {
    display: flex;
  }
`

var FSFormInfo = styled.div`
  display: none;
  width: 47%;
  margin: 1.25rem 1% 1.25rem 4%;

  ${device.tablet} {
    display: block;
  }
`

function CaseStudies({ errors, touched, isSubmitting, setFieldValue }) {
  var data = useStaticQuery(graphql`
    query {
      caseStudyTopImg: file(
        relativePath: { eq: "I2_Optimization_Fracking_Bi_Fuel_Solutions.jpeg" }
      ) {
        ...fluidImageSVG
      }
      i2TitleIcon: file(relativePath: { eq: "i2SmallIcon.png" }) {
        ...fluidImage
      }
    }
  `)

  function reCaptchaChange(val) {
    setFieldValue("reCaptchaValue", val)
  }

  return (
    <Layout>
      <SEO
        title="Optimization Case Studies"
        description="Learn about Oil & Gas Modernization, get the inside scoop on innovative tools and cutting-edge technology."
      />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.caseStudyTopImg.sharp.fluid}
          alt="Optimizing Oil and Gas Drilling, Production, and Completions across North America"
        />
        <PageSectionTitle>
          Case <I2BronzeText>Studies</I2BronzeText>
        </PageSectionTitle>
        <PageImageButton to="/contact-us/">Contact Us</PageImageButton>
      </PageTopFullSizeImgCont>

      <SectionContentContainer>
        <BodyText>
          <BodyBold>
            Get an inside look at real world optimization case studies.
          </BodyBold>
        </BodyText>

        <BodyText>
          Sign up to{" "}
          <BodyBold>
            <I2BronzeText>i2O Insights,</I2BronzeText> our quarterly newsletter
          </BodyBold>{" "}
          to see the increased efficiency and cost savings optimization could
          unlock <BodyBold>for your operation.</BodyBold>
        </BodyText>
      </SectionContentContainer>

      <SectionContentContainer>
        <SignUpTitleCont>
          <SignUpTitle>Sign Up for</SignUpTitle>
          <I2IconCont>
            <Image
              style={{ height: "inherit" }}
              objectFit="cover"
              fluid={data.i2TitleIcon.sharp.fluid}
              alt="i2 Optimization, streamlining Oil and Gas Drilling, Completions, and Production."
            />
          </I2IconCont>{" "}
          <SignUpTitle>
            <I2BronzeText>i2O Insights:</I2BronzeText>
          </SignUpTitle>
        </SignUpTitleCont>

        <FormSectionCont>
          <FormContainer>
            <label htmlFor="emailInput">Email:</label>
            <ErrorsContainer>
              {touched.email && errors.email && <span>{errors.email}</span>}
            </ErrorsContainer>
            <EmailInput
              error={touched.email && errors.email ? 1 : 0}
              id="emailInput"
              placeholder="Email"
              required
              type="email"
              name="email"
            />
            <label htmlFor="companyInput">Company:</label>
            <ErrorsContainer>
              {touched.company && errors.company && (
                <span>{errors.company}</span>
              )}
            </ErrorsContainer>
            <CompanyInput
              error={touched.company && errors.company ? 1 : 0}
              id="companyInput"
              placeholder="Company"
              type="text"
              name="company"
              required
            />

            <ErrorsContainer>
              {touched.reCaptchaValue && errors.reCaptchaValue && (
                <span>{errors.reCaptchaValue}</span>
              )}
            </ErrorsContainer>
            <ReCaptcha
              onChange={reCaptchaChange}
              onBlur={reCaptchaChange}
              onExpired={reCaptchaChange}
              sitekey={process.env.RECAPTCHA_SITE_KEY}
            />
            <SignUpButton type="submit" disabled={isSubmitting}>
              Sign Up
            </SignUpButton>
          </FormContainer>
          <FSFormInfo>
            <BodyText>
              Your contact info will be used for the sole purpose of sending you
              our quarterly <BodyBold>i2O Insights</BodyBold> newsletter.
            </BodyText>
            <BodyText>
              We will not contact you for any other reason without your
              permission.
            </BodyText>

            <BodyText>
              To unsubscribe at any time,{" "}
              <BodyLink to="/contact-us/">Contact Us.</BodyLink>
            </BodyText>
          </FSFormInfo>
        </FormSectionCont>
      </SectionContentContainer>
    </Layout>
  )
}

var CaseStudiesFormik = withFormik({
  mapPropsToValues({ email, company, reCaptchaValue }) {
    return {
      email: ``,
      company: ``,
      reCaptchaValue: ``,
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Please provide a valid email")
      .required("Please provide your email address"),
    company: Yup.string()
      .matches(/^(?=.*[A-Z0-9]{1,})[\w.,!&?"'/()$ ]+$/i, {
        message: "Please provide company name",
      })
      .required("Please provide your company name"),
    reCaptchaValue: Yup.string().required("Please check the box below."),
  }),
  handleSubmit(values, { setSubmitting }) {
    async function handleInputs(values) {
      var { email, company, reCaptchaValue } = values

      var contactEmail = email.trim()
      var contactCompany = company.trim()

      await axios({
        url:
          "https://lw5xxirgx0.execute-api.us-east-1.amazonaws.com/dev/case-studies-signup",
        method: "post",
        data: {
          contactEmail: contactEmail,
          contactCompany: contactCompany,
          reCaptchaValue: reCaptchaValue,
        },
      })
        .then(function axiosReturn() {
          navigate("/case-studies-sign-up-confirm/")
        })
        .catch(function axiosRequestFail() {
          alert(
            "Our Sign Up Service is Down At the Moment. Please try again later."
          )
        })
    }
    handleInputs(values)
    setSubmitting(false)
  },
})(CaseStudies)

export default CaseStudiesFormik
